// @flow strict
import React, { useState, useRef } from "react";
import * as yup from "yup";
import Layout from "../components/Layout";
import Sidebar from "../components/Sidebar";
import Page from "../components/Page";
import { useSiteMetadata } from "../hooks";
import ReCAPTCHA from "react-google-recaptcha";
import styles from "./questions.module.scss";
import firebase from "gatsby-plugin-firebase";
import { Formik, Field, Form } from "formik";

const Questions = ({ data, location }) => {
  const { title: siteTitle, subtitle: siteSubtitle } = useSiteMetadata();
  const pageTitle = siteTitle;

  const [err, setErr] = useState("");
  const recaptchaRef = useRef(null);

  const submitQuestion = async (values, { resetForm, setSubmitting }) => {
    // console.log("submitQuestion");
    const captcha = recaptchaRef.current.getValue();
    recaptchaRef.current.reset();
    // console.log(captcha);
    if (!captcha) {
      setErr("You must complete the recaptcha");
    }
    // console.log("submitted");
    const submit = firebase.functions().httpsCallable("submitQuestion");
    submit({ ...values, captcha })
      .then((res) => {
        console.log(res);
        if (res.data === false || res.error) {
          throw res.error;
        }
        // console.log("Form has been submitted");
        setErr(false);
        resetForm();
      })
      .catch((err) => {
        console.log(err);
        setErr("An error has occured. Please try again later.");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Layout title={pageTitle} description={siteSubtitle}>
      <Sidebar />
      <Page>
        <h1 style={{ marginTop: "0" }}>Ask Me Questions</h1>
        Apologies in advance if it takes a while to answer. Sometimes I forget
        that this website exists.
        <Formik
          initialValues={{
            name: "",
            question: "",
          }}
          validationSchema={yup.object().shape({
            name: yup.string().required(),
            question: yup.string().required(),
          })}
          onSubmit={submitQuestion}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form className={styles["question-form"]}>
              <label htmlFor="name">Name</label>
              <Field id="name" name="name" placeholder="Name" required />
              {errors.name && touched.name ? (
                <div style={{ color: `red` }}>{errors.name}</div>
              ) : null}
              <label htmlFor="question">Question</label>
              <Field
                id="question"
                name="question"
                as="textarea"
                placeholder="Question"
                required
              />
              {errors.question && touched.question ? (
                <div style={{ color: `red` }}>{errors.question}</div>
              ) : null}
              <ReCAPTCHA
                required
                ref={recaptchaRef}
                sitekey="6LdFsdoZAAAAAJ7yL-30ylQyiv9I5up1e3-Czorv"
                style={{ marginTop: `1em` }}
              />
              <br />
              <button disabled={isSubmitting} type="submit" value="Submit">
                Submit{isSubmitting && "ting..."}
              </button>
              {err && <p style={{ color: `red` }}>{err}</p>}
            </Form>
          )}
        </Formik>
      </Page>
    </Layout>
  );
};

export default Questions;
